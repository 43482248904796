/* Shared custom styles between app and web */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-right: env(safe-area-inset-right);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  font-size: 15px;
  @apply text-charcoal;
}

@media screen and (min-width: 768px) {
  :root {
    font-size: 16px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-y: scroll;
  overflow-x: hidden !important;
}

html {
  @apply text-charcoal;
}

/* .text-2xl {
  @apply text-3xl;
  @apply font-bold;
} */

@layer base {
  .font-outline {
    color: white;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  }
}

@layer utilities {
  .scale-highlight {
    /* transform: scale(1.03); */
    /* transform: translate3d(0, 0, -50%); */
    transform: scale(110%);
    filter: brightness(1.05);
  }
}

.web-container {
  @apply !max-w-6xl;
  @apply !mx-auto;
  @apply !w-[95vw];
  @apply !pb-10;
}

.flow-container {
  @apply relative;
  @apply !max-w-2xl;
  @apply !mx-auto;
  @apply !w-[90%];
  @apply !mb-20;
}

.app-container {
  @apply !max-w-4xl;
  @apply !mx-auto;
  @apply !w-[95vw];
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.thin-scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 15px;
  position: relative;
  right: 10px;
}

.side-scroll {
  padding: 8px 8px 14px 8px;
  overflow: scroll;
  clip-path: inset(0 0 8px 0);
}

.side-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::webkit::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

.ios-cut-x-scrollbar {
  padding-bottom: 7px;
  clip-path: inset(0 0 7px 0);
}
