@import '../../tailwind.style.css';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#__next {
  min-height: 100vh !important;
}

input,
select {
  outline-width: 0;
}
